application.register("comp_reservation_landing_page", class extends Stimulus.Controller {
    toggleForm(e) {
        e.preventDefault();
        let order = this.targets.find('form');

        if(order.classList.contains('display--none')) {
            order.classList.remove('display--none')
        } else {
            order.classList.add('display--none')
        }
    }

});
