application.register("part_form_booking", class extends Stimulus.Controller {

    get target() {
        return {
            destinations: this.targets.find("destinations"),
            calendar: this.targets.find("calendar"),
            calendarInner: this.targets.find("calendarInner"),
            calendarText: this.targets.find("calendarText"),
            people: this.targets.find("people"),
            peopleText: this.targets.find("peopleText"),
            arrivalTitle: this.targets.find("arrivalTitle"),
            arrivalLabel: this.targets.find("arrivalLabel"),
            arrivalInput: this.targets.find("arrivalInput"),
            departureTitle: this.targets.find("departureTitle"),
            departureLabel: this.targets.find("departureLabel"),
            departureInput: this.targets.find("departureInput"),
            destinationGeo: this.targets.find("destinationGeo"),
            destinationResults: this.targets.find("destinationResults"),
            destinationText: this.targets.find("destinationText"),
            destinationInputId: this.targets.find("destinationInputId"),
            destinationInputType: this.targets.find("destinationInputType"),
            mobileTitle: this.targets.find("mobileTitle"),
            mobileLabel: this.targets.find("mobileLabel"),
            adultsInput: this.targets.find("adultsInput"),
            childrenInput: this.targets.find("childrenInput"),
            // babyInput: this.targets.find("babyInput"),
            price: this.targets.find("price"),
        }
    }

    connect() {
        if (!navigator.geolocation) {
            let geoLink = this.element.querySelector(`[data-action="click->part_form_booking#getGeolocation"]`);
            if (geoLink !== null) {
                geoLink.parentElement.removeChild(geoLink);
            }
        }
    }

    showDropdown(elm) {

        if (!elm) {
            return;
        }

        let self = this,
            dropdown = elm.querySelector(`[data-ref="dropdown"]`);

        elm.classList.add("state--active");
        elm.closest(".col").classList.add("state--active");
        self.element.classList.add("state--active");

        if (dropdown !== null) {
            if (document.querySelector(".lib--dialog") === null) {
                self.element.insertAdjacentHTML("beforebegin", `<div class="lib--dialog"></div>`);
                document.querySelector(".lib--dialog").style["display"] = "flex";
                document.querySelector("#layout_header").style.zIndex = "1002";
                document.querySelector(".lib--dialog").addEventListener("mousedown", (e) => {
                    document.documentElement.addEventListener("mouseup", function e() {
                        LibDialog.hide();
                        self.element.classList.remove("state--active");
                        self.element.querySelectorAll(`.elm_form_item`).forEach((elm) => {
                            elm.classList.remove("state--active");
                            elm.closest(".col").classList.remove("state--active");
                            elm.querySelector(`[data-ref="dropdown"]`).classList.remove("state--active");
                        });
                        document.querySelector("#layout_header").style.zIndex = null;
                        document.documentElement.removeEventListener("mouseup", e);
                    });
                }, true);
            } else {
                clearTimeout(self._backdropTimeout);
                document.querySelector(".lib--dialog").classList.remove("state--hiding");
                document.querySelector(".lib--dialog").style["display"] = "flex";
            }
            dropdown.classList.add("state--active");
        }
    }

    hideDropdown(elm) {
        if (!elm) {
            return;
        }

        let self = this,
            dropdown = elm.querySelector(`[data-ref="dropdown"]`);

        elm.closest(".col").classList.remove("state--active");
        elm.classList.remove("state--active");

        if (dropdown !== null) {
            if (dropdown.classList.contains("state--active")) {
                dropdown.classList.add("state--hiding");
                dropdown.classList.remove("state--active");
                setTimeout(() => {
                    dropdown.classList.remove("state--hiding")
                }, 300);

                if (document.querySelector(".lib--dialog") !== null) {
                    document.querySelector(".lib--dialog").classList.add("state--hiding");
                    self._backdropTimeout = setTimeout(() => {
                        if (document.querySelector(".lib--dialog") !== null) {
                            document.querySelector(".lib--dialog").style["display"] = "none";
                            document.querySelector(".lib--dialog").remove();
                        }
                    }, 300);
                }
            }
        }

    }

    showDestinations() {
        let self = this;
        self.hideDropdown(self.target.calendar);
        self.hideDropdown(self.target.people);
        self.showDropdown(self.target.destinations);
    }

    updateDestinations(e) {
        this.target.destinationInputId.value = "";
        this.target.destinationInputType.value = "";

        let query = e.currentTarget.value;
        let self = this;

        if (query !== "") {
            let formData = new FormData();
            formData.append('q', query);

            fetch('/search/all', {
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                method: 'POST',
                body: formData
            }).then((response) => {
                return response.json();
            }).then((data) => {
                self.target.destinationResults.innerHTML = data.html;
            });
        }
    }

    setDestination(e) {
        let self = this, elm = e.currentTarget;

        self.target.destinationText.value = elm.dataset.actionValue;
        self.target.destinationInputId.value = elm.dataset.actionId;
        self.target.destinationInputType.value = elm.dataset.actionType;

        self.hideDropdown(self.target.destinations);
        self.hideDropdown(self.target.people);
        self.showCalendar();

        self.sendGaEvent();
    }

    clearDestination() {
        let self = this;

        self.target.destinationText.value = "";
        self.target.destinationInputId.value = "";
        self.target.destinationInputType.value = "";

        self.hideDropdown(self.target.destinations);
        self.hideDropdown(self.target.people);
    }

    clearCalendar() {
        let self = this;

        self.target.calendarText.value = "";
        self.target.arrivalInput.value = "";
        self.target.departureInput.value = "";

        self.hideDropdown(self.target.calendar);
    }

    clearPeople() {
        let self = this;

        self.target.peopleText.value = "";

        self.hideDropdown(self.target.people);
    }

    getGeolocation(e) {
        let self = this, elm = e.currentTarget;
        let lng = JSON.parse(elm.dataset.actionLng);

        self.target.destinationText.value = lng['searching'];
        navigator.geolocation.getCurrentPosition(
            (position) => {
                self.target.destinationText.value = elm.dataset.actionValue;
                self.target.destinationInputId.value = `${position.coords.latitude}, ${position.coords.longitude}`;
                self.target.destinationInputType.value = "gps";

                self.hideDropdown(self.target.destinations);
                self.hideDropdown(self.target.people);
                self.showCalendar();
            },
            () => {
                self.target.destinationText.value = lng['error'];
                self.target.destinationInputId.value = "";
                self.target.destinationInputType.value = "";

                self.hideDropdown(self.target.destinations);
                self.hideDropdown(self.target.calendar);
                self.hideDropdown(self.target.people);
            });
    }

    showCalendar() {
        let self = this;

        if (self._calendarGenerated !== true) {
            let lang = document.documentElement.getAttribute("lang");
            importStyle(cdnjs.litepicker_css);
            importScript([cdnjs.litepicker, cdnjs.dayjs, cdnjs.dayjs_locale.replace("/cs.js", `/${lang}.js`)], () => {
                self._backdropTimeout = "";
                dayjs.locale(lang);
                self._calendarGenerated = true;
                let lockDays = self.target.arrivalInput.dataset.lockDays;
                let maxDate = self.target.arrivalInput.dataset.maxDate;
                let minDate = self.target.arrivalInput.dataset.minDate;

                new Litepicker({
                    element: self.target.calendarInner,
                    lang: lang,
                    numberOfMonths: 2,
                    numberOfColumns: 2,
                    minDate: minDate ? minDate : dayjs(),
                    maxDate: maxDate ? maxDate : null,
                    lockDays: lockDays ? JSON.parse(lockDays) : [],
                    selectForward: true,
                    inlineMode: true,
                    singleMode: false,
                    showTooltip: false,
                    buttonText: {
                        previousMonth: '<span class="icon icon--arrow-left"></span>',
                        nextMonth: '<span class="icon icon--arrow-right"></span>'
                    },
                    onRender: (element) => {
                        element.querySelectorAll("[href]").forEach((elm) => {
                            elm.removeAttribute("href");
                            elm.setAttribute("data-no-swup", true);
                        })
                    },
                    onSelect: (date1, date2) => {
                        self.target.arrivalTitle.classList.remove("text--color-a");
                        self.target.departureTitle.classList.remove("text--color-a");
                        self.target.arrivalLabel.textContent = date1.toLocaleDateString(lang, {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                        });
                        self.target.departureLabel.textContent = date2.toLocaleDateString(lang, {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                        });
                        self.target.mobileLabel.textContent = `${date1.toLocaleDateString(lang, {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                        })} - ${date2.toLocaleDateString(lang, {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                        })}`
                        self.target.arrivalInput.value = dayjs(date1).format("YYYY-MM-DD");
                        self.target.departureInput.value = dayjs(date2).format("YYYY-MM-DD");
                        self.target.calendarText.value = `${date1.toLocaleDateString(lang, {
                            weekday: undefined,
                            year: undefined,
                            month: 'numeric',
                            day: 'numeric'
                        })} - ${date2.toLocaleDateString(lang, {
                            weekday: undefined,
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                        })}`

                        self.target.departureInput.dispatchEvent(new Event('change', {'bubbles': true}));

                        self.hideDropdown(self.target.destinations);
                        self.hideDropdown(self.target.calendar);
                        self.showDropdown(self.target.people);

                        self.sendGaEvent();
                    },
                });
            });

            setTimeout(() => {
                self.hideDropdown(self.target.destinations);
                self.hideDropdown(self.target.people);
                self.showDropdown(self.target.calendar);
            }, 500);
        } else {
            self.hideDropdown(self.target.destinations);
            self.hideDropdown(self.target.people);
            self.showDropdown(self.target.calendar);
        }
    }

    showPeople() {
        let self = this;
        self.hideDropdown(self.target.destinations);
        self.hideDropdown(self.target.calendar);
        self.showDropdown(self.target.people);
    }

    updatePeople(event) {
        let self = this;

        let adults = parseInt(this.target.adultsInput.value) || 0;
        let children = parseInt(this.target.childrenInput.value) || 0;
        // let baby = parseInt(this.target.babyInput.value) || 0;
        // let numberPerson = (adults + children + baby);
        let numberPerson = (adults + children);
        let maxCapacity = parseInt(event.target.dataset.maxCapacity) || 0;

        let formData = new FormData();
        formData.append('adults', adults);
        formData.append('children', children);
        // formData.append('baby', baby);

        if(numberPerson <= maxCapacity || maxCapacity === 0) {

            fetch('/api/people-text', {
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                method: 'POST',
                body: formData
            }).then((response) => {
                return response.json();
            }).then((data) => {

                self.target.peopleText.value = data.text;
            });

            self.sendGaEvent();

        } else {
            event.target.value = parseInt(event.target.value) - 1;
            event.preventDefault();
            alert(event.target.dataset.textMaxCapacity + ' ' + maxCapacity);
        }

        self.hideDropdown(self.target.people);
    }

    updatePrice() {
        let self = this;

        let formData = new FormData();
        formData.append('checkIn', this.target.arrivalInput.value);
        formData.append('checkOut', this.target.departureInput.value);
        formData.append('adults', this.target.adultsInput.value);
        formData.append('children', this.target.childrenInput.value);
        // formData.append('baby', this.target.babyInput.value);
        formData.append('place', this.element.dataset.place);

        fetch('/api/price', {
            headers: {'X-Requested-With': 'XMLHttpRequest'},
            method: 'POST',
            body: formData
        }).then((response) => {
            return response.json();
        }).then((data) => {
            self.target.price.textContent = data.price;
        });
    }

    changeRoomDate() {
        let mainForm = document.getElementById('allRoomForm')
        mainForm.querySelector('[name="checkIn"]').value = this.target.arrivalInput.value;
        mainForm.querySelector('[name="checkOut"]').value = this.target.departureInput.value;
        mainForm.querySelector('[name="dateText"]').value = this.target.arrivalInput.value + ' - ' + this.target.departureInput.value;
        mainForm.querySelector('[name="checkIn"]').dispatchEvent(new Event('change', {'bubbles': true, 'calcelable': true}));
    }

    sendGaEvent() {
        try {
            dataLayer.push({
                'event': 'searchFilter',
                'poloha': this.target.destinationText.value,
                'timing_od': this.target.arrivalInput.value,
                'timing_do': this.target.departureInput.value,
                'dospeli': this.target.adultsInput.value,
                // 'deti_do_6': this.target.babyInput.value,
                'deti_od_6': this.target.childrenInput.value,
            });
        } catch (err) {
        }
    }
});
