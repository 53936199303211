application.register("lib-tel", class extends Stimulus.Controller {
    connect() {
        const input = this.element.querySelector('input');

        importStyle(cdnjs.intlTelInput_css);
        importScript(cdnjs.intlTelInput, () => {
            intlTelInput(input,{
                preferredCountries: ['cz','sk','pl','hu','de','ru'],
                hiddenInput: "tel"
            })
        });
    }
});
