application.register("part_dialog_story_detail", class extends Stimulus.Controller {

    get target() {
        return {
            video: this.targets.find("video"),
            progressbar: this.targets.find("progressBar"),
            playButton: this.targets.find("playButton"),
            muteButton: this.targets.find("muteButton"),
            prev: this.targets.find("prev"),
            next: this.targets.find("next"),
            likeButton: this.targets.find("likeButton"),
        }
    }

    connect() {
        importScript(cdnjs.swipedevents);
    }

    updateProgress() {
        let progress = Math.floor((100 / this.target.video.duration) * this.target.video.currentTime);
        if(!isNaN(progress)) {
            this.target.progressbar.value = progress;
        }
    }

    togglePause() {
        let lng = JSON.parse(this.target.playButton.dataset.actionLng);

        if(this.target.video.paused) {
            this.target.video.play();
            this.target.playButton.classList.remove("state--active");
            this.target.playButton.setAttribute("aria-label",lng["inactive"]);
        }
        else {
            this.target.video.pause();
            this.target.playButton.classList.add("state--active");
            this.target.playButton.setAttribute("aria-label",lng["active"]);
        }
    }

    toggleMute() {
        let lng = JSON.parse(this.target.muteButton.dataset.actionLng);

        if(this.target.video.muted) {
            this.target.video.muted = false;
            this.target.muteButton.classList.remove("state--active");
            this.target.muteButton.setAttribute("aria-label",lng["inactive"]);
        }
        else {
            this.target.video.muted = true;
            this.target.muteButton.classList.add("state--active");
            this.target.muteButton.setAttribute("aria-label",lng["active"]);
        }
    }

    toggleLike(e) {
        naja.makeRequest('POST', e.currentTarget.dataset.url, null, {'history' : false});

        this.target.likeButton.querySelector('span.icon-l').classList.toggle('icon--like-fill');
        this.target.likeButton.querySelector('span.icon-l').classList.toggle('icon--like');
    }

    handleKeydown(e) {
        if(e.code === "Space") {
            e.preventDefault();
            this.togglePause();
        }
    }

    loadDialog(e) {
        if(e.detail.dir === "right" || e.detail.dir === "left") {
            e.preventDefault();
            e.stopPropagation();
        }
        if(e.detail.dir === "right") {
            //prev
            if(typeof this.target.prev !== "undefined") {
                this.target.prev.dispatchEvent(new Event('click', {'bubbles': true, 'cancelable': true}));
            }
        }
        if(e.detail.dir === "left") {
            //next
            if(typeof this.target.next !== "undefined") {
                this.target.next.dispatchEvent(new Event('click', {'bubbles': true, 'cancelable': true}));
            }
        }
    }

    shareStory() {
        importScript(cdnjs.shareApiPolyfill, () => {
            let keywords = document.head.querySelector('meta[name="keywords"]'),
                url = document.head.querySelector('meta[name="canonical"]'),
                description = document.head.querySelector('meta[name="description"]'),
                lang = document.documentElement.lang;

            if (["cs", "de", "da", "en", "es", "fr", "ja", "ko", "nl", "pt", "ru", "sk", "tr", "zh"].indexOf(lang) === -1) {
                lang = "en";
            }

            navigator.share({
                    title: document.title,
                    text: (description !== null) ? description.getAttribute("content") : "",
                    url: (url !== null) ? url.getAttribute("content") : location.href,
                    hashtags: (keywords !== null) ? keywords.getAttribute("content") : ""
                },
                {
                    copy: true,
                    email: true,
                    print: false,
                    sms: false,
                    messenger: true,
                    facebook: true,
                    whatsapp: true,
                    twitter: true,
                    linkedin: true,
                    telegram: false,
                    skype: false,
                    language: lang
                }
            )
                .then()
                .catch(error => console.log(error));
        });
    }
});