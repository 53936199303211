application.register("part_ui_video", class extends Stimulus.Controller {
    connect() {
        this._video = this.targets.find('video')
        const attribute = this._video.getAttribute('data-action')
        const action = 'play->part_ui_video#handlePlay pause->part_ui_video#handlePause'

        if (attribute === null) {
            this._video.setAttribute('data-action', action)
        } else if (attribute.indexOf(action) === -1) {
            this._video.setAttribute('data-action', `${attribute} ${action}`)
        }
    }

    play(e) {
        e.preventDefault()
        this._video.play()
    }

    handlePlay() {
        this.element.classList.add('state--playing')
        this._video.setAttribute('controls', 'controls')
    }

    handlePause() {
        this.element.classList.remove('state--playing')
        this._video.removeAttribute('controls')
    }
})
