application.register("layout_header", class extends Stimulus.Controller {

    get target() {
        return {
            search: this.targets.find("search")
        }
    }

    connect() {
        let self = this;

        self._currentScrollY = 0;
        self._latestKnownScrollY = 0;
        self._ticking = false;
        self._offset = 5;

        bodyLoaded(() => {
            setTimeout(() => {
                if (typeof locomotive !== "undefined" && !locomotive.isMobile) {
                    locomotive.on("scroll", (e) => {
                        self.onScroll(e.delta.y);
                    });
                } else {
                    window.addEventListener('scroll', ()=>{
                        self.onScroll(window.scrollY);
                    }, false);
                }
            }, 100);
        })
    }

    disconnect() {
        let self = this;

        window.removeEventListener('scroll', ()=>{
            self.onScroll(window.scrollY);
        }, false);
    }

    onScroll(currentScrollY) {
        let self = this, element = self.element;

        if (!self._ticking) {
            requestAnimationFrame(() => {
                if (currentScrollY >= self._offset) {
                    element.classList.add("header--not-top");
                    if (currentScrollY > self._latestKnownScrollY) {
                        element.classList.remove("header--pinned");
                        element.classList.add("header--unpinned");
                    } else {
                        element.classList.remove("header--unpinned");
                        element.classList.add("header--pinned");
                    }
                } else {
                    element.classList.remove("header--not-top", "header--pinned", "header--unpinned");
                }
                self._latestKnownScrollY = currentScrollY;
            });

            self._ticking = false;
        } else {
            self._ticking = true;
        }
    }

    searchOpen() {
        let self = this;

        self.element.classList.add("is--search");
        this.target.search.classList.add("state--active");
        this.target.search.querySelector("input").focus();
    }

    searchClose() {
        let self = this;
        this.target.search.classList.add("state--hiding");
        this.target.search.classList.remove("state--active");
        self._searchTimeout = setTimeout(() => {
            this.target.search.classList.remove("state--active", "state--hiding");
            self.element.classList.remove("is--search");
        }, 350)
    }
});