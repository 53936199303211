application.register("part_ui_heading", class extends Stimulus.Controller {
    connect() {
        let element = this.element;
        let decos = element.querySelectorAll('[class*="deco--"]');

        if (decos[0] !== null) {

            inView(element,()=>{
                decos.forEach((deco) => {
                    const name = deco.className.replace("deco--", "deco-");
                    const isPop = name.indexOf("deco-pop") > -1;
                    const isUnderline = name === "deco-underline";
                    new Vivus(deco, {
                        type: isPop ? "sync" : "oneByOne",
                        file: `/assets/images/${name}.svg`,
                        selfDestroy: true,
                        start: "autostart",
                        duration: (isUnderline || isPop) ? 50 : 100,
                        onReady: function (inst) {
                            inst.el.removeAttribute('height');
                            inst.el.removeAttribute('width');
                        }
                    })
                });
            }, {
                rootMargin: "0px"
            })

        }
    }
});