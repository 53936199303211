application.register("lib-fetch", class extends Stimulus.Controller {
    link(e) {
        e.preventDefault();

        let element = e.currentTarget;

        fetch(element.getAttribute("href"), {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
            return response.json();
        }).then((payload) => {
            fetchHandler(payload);
        });
    }

    linkComponent(e) {
        e.preventDefault();

        let url = e.currentTarget.getAttribute('data-href');

        if (!url || !url.length) {
            url = e.currentTarget.getAttribute('href');
        }

        if (!url || !url.length) {
            console.log('no href attribute for fetch component link');
            return;
        }

        fetch(url, {method: 'POST', headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
            return response.json();
        }).then((payload) => {
            fetchHandler(payload, function () {
                if (typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                    LibDialog.show(payload.dialog);
                }
            });
        });
    }

    form(e) {
        e.preventDefault();

        let frm = e.currentTarget,
            formData = new FormData(frm);

        frm.querySelector('button[type=submit]').classList.add('state--loading');

        fetch(frm.getAttribute('action'), {
            method: frm.getAttribute('method'),
            body: new URLSearchParams(formData),
            headers: {'X-Requested-With': 'XMLHttpRequest'}
        }).then((response) => {
            return response.json();
        }).then((payload) => {
            fetchHandler(payload, function () {
                if (typeof payload["dialog"] !== 'undefined' && payload["dialog"].length) {
                    LibDialog.show(payload["dialog"]);
                }
            });
        }).then(() => {
            frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
        });
    }
});