application.register("part_dialog_review_add", class extends Stimulus.Controller {

    get target() {
        return {
            location: this.targets.find("location"),
            locationId: this.targets.find("locationId"),
            rating: this.targets.find("rating")
        }
    }

    updateRating(e) {
        let value = e.currentTarget.value;

        this.target.rating.classList.remove("is--bad", "is--neutral", "is--good");

        switch (parseInt(value)) {
            case 1:
            case 2:
                this.target.rating.classList.add("is--bad");
                break;
            case 3:
                this.target.rating.classList.add("is--neutral");
                break;
            case 4:
            case 5:
                this.target.rating.classList.add("is--good");
                break;
            default:
                break;
        }

        this.target.rating.querySelectorAll(".elm_rating_item").forEach((item, index) => {
            item.classList.remove("state--active");
            if (index <= value - 1) {
                item.classList.add("state--active");
            }
        });
    }

    setLocation(e) {
        this.target.location.value = e.currentTarget.dataset.actionValue;
        this.target.locationId.value = e.currentTarget.dataset.actionId;
    }
});