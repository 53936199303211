loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget);
    }

    updateVisited(e) {
        let elm = e.currentTarget;
        let formData = new FormData();
        formData.append('value', elm.checked ? 'add' : 'remove');

        fetch(elm.dataset.url, {headers: {'X-Requested-With': 'XMLHttpRequest'}, method: 'POST', body: formData}).then((response) => {
            return response.json();
        });
    }

    updateFavourite(e) {
        let elm = e.currentTarget;
        let formData = new FormData();
        formData.append('value', elm.checked ? 'add' : 'remove');

        fetch(elm.dataset.url, {headers: {'X-Requested-With': 'XMLHttpRequest'}, method: 'POST', body: formData}).then((response) => {
            return response.json();
        });
    }

    appendList() {
        let list = document.querySelector('#snippet--list');
        if (list) {
            list.setAttribute('data-naja-snippet-append', '');
        }
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        let element = this.element;

        if (typeof element.dataset.libDialogOpen !== "undefined") {
            let url = element.getAttribute("data-action-url");

            if (url) {
                LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")));
            } else {
                LibDialog.show(document.querySelector(element.dataset.libDialogOpen).innerHTML, () => loadStimulus(document.querySelector(".lib--dialog")));
            }
        }
    }

    show(e) {
        let element = e.currentTarget;
        let slider = element.closest("[data-lib-ns]");
        let url = element.getAttribute("data-action-url");

        if (slider !== null && slider.classList.contains("is--grabbing")) {
            e.preventDefault();
            return;
        }

        LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide();
    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        LibTabs(this.element);
    }
});

application.register("lib-ns", class extends Stimulus.Controller {
    connect() {
        if (!('scrollBehavior' in document.documentElement.style)) {
            importScript(cdnjs.seamless, () => seamless.polyfill());
        }

        bodyLoaded(() => LibNativeSlider(this.element.querySelector("[data-lib-ns]"), this.element))
    }
});

application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        let element = this.element, options = this.data.get("options");
        bodyLoaded(() => {
            if (importScript.used.indexOf(cdnjs.tippy) === -1) {
                setTimeout(() => {
                    importStyle(cdnjs.tippy_css);
                    importScript([cdnjs.popper, cdnjs.tippy], () => {
                        LibTippy(element, options);
                    });
                }, 500);
            } else {
                LibTippy(element, options);
            }
        })
    }
});