application.register("lib-switches", class extends Stimulus.Controller {

    get target() {
        return {
            visited: this.targets.find("visited"),
            wishlist: this.targets.find("wishlist")
        }
    }

    updateSwitch(e) {
        let currentItem = e.currentTarget;

        if (currentItem === this.target.visited && currentItem.checked) {
            this.target.wishlist.checked = false;
        }

        if (currentItem === this.target.wishlist && currentItem.checked) {
            this.target.visited.checked = false;
        }
    }
});