application.register("comp_account", class extends Stimulus.Controller {

    get target() {
        return {
            nav: this.targets.find("nav")
        }
    }

    connect() {
        this.target.nav.addEventListener("scroll", () => {
            this.checkNavEnd();
        }, {passive: true});

        let navActive = this.target.nav.querySelector(".state--active");

        if (navActive !== null) {
            this.target.nav.scrollLeft = navActive.offsetLeft;

        }
    }

    updateNav(e) {
        let navCurrent = e.currentTarget,
            navActive = navCurrent.closest(`[data-target="comp_account.nav"]`).querySelector(".state--active");

        this.target.nav.scrollLeft = navCurrent.offsetLeft;

        if (navActive !== null && navActive !== navCurrent) {
            navActive.classList.remove("state--active");
            navCurrent.classList.add("state--active");
        }
    }

    checkNavEnd() {
        if(this.target.nav.scrollLeft === this.target.nav.scrollWidth - this.target.nav.clientWidth) {
            this.element.classList.add("is--nav-end");
        }
        else {
            this.element.classList.remove("is--nav-end");
        }
    }
});