application.register("part_filter", class extends Stimulus.Controller {
    connect() {
        inView(this.element, () => {
            let selector = this.element;
            let self = this;

            let range = selector.querySelectorAll(".part_ui_range");
            if (range !== null) {
                [...range].forEach((elm) => {
                    importScript(cdnjs.noui, () => {
                        LibNoUI(elm, {
                            elm_min: elm.querySelector(`[data-range-input="min"]`),
                            elm_max: elm.querySelector(`[data-range-input="max"]`),
                            elm_min_hidden: elm.querySelector(`[data-range-input="min"]`).nextElementSibling,
                            elm_max_hidden: elm.querySelector(`[data-range-input="max"]`).nextElementSibling,
                            unit: elm.dataset.unit
                        });
                    });
                });
            }

            selector.addEventListener("change", function (e) {
                for (let target = e.target; target && target !== this; target = target.parentNode) {
                    if (target.matches('[data-do-filter-change]')) {
                        // self.doCatalogFilter(target,0,1);
                        break;
                    }
                    if (target.matches('[data-catalog-filter-check]')) {
                        // self.doCatalogFilter(target,0,1);
                        break;
                    }
                }
            }, false);
        });
    }

    cancelFilters(event) {
        event.preventDefault();

        this.element.querySelectorAll('input[type=checkbox]').forEach((elm) => {
            elm.checked = false
        });

        this.priceRangeBackToDefaults();

        this.element.dispatchEvent(new Event('submit', {'bubbles': true}));
    }

    priceRangeBackToDefaults() {
        let selector = this.element;
        let defaultMin = selector.querySelector('input[name="priceMinDefault"]');
        let defaultMax = selector.querySelector('input[name="priceMaxDefault"]');
        if (defaultMin !== null) {
            selector.querySelector('[data-range-input="min"]').value = defaultMin.value;
            selector.querySelector('[data-range-input="min"]').dispatchEvent(new Event('change', {'bubbles': true}));
        }
        if (defaultMax !== null) {
            selector.querySelector('[data-range-input="max"]').value = defaultMax.value;
            selector.querySelector('[data-range-input="max"]').dispatchEvent(new Event('change', {'bubbles': true}));
        }
    }

    setFilter(key, value) {
        var element = document.querySelector('.comp_filter_list');
        var form = element.querySelector('form');

        window.scrollTo({top: element.offsetTop});

        form.querySelectorAll(`[type="checkbox"]`).forEach(function (item) {
            item.checked = false;
        });

        form.querySelector(`[name="${key}"][value="${value}"]`).checked = true;

        form.dispatchEvent(new Event('submit', {'bubbles':true}));
    }
});