application.register("part_ui_upload", class extends Stimulus.Controller {
    get target() {
        return {
            dropzone: this.targets.find("dropzone"),
            files: this.targets.find("files")
        }
    }

    connect() {
        if (typeof this.target.dropzone !== "undefined") {
            let self = this;

            self._hasVideo = false;
            self._limitToImages = false;

            importScript(cdnjs.dropzone, () => {

                new Dropzone(this.target.dropzone, {
                    timeout: 300 * 1000,
                    url: "?do=uploadFile",
                    acceptedFiles: "image/jpeg, image/png, video/mp4, video/quicktime",
                    previewTemplate: "<div class=\"dz-preview dz-file-preview\"><img data-dz-thumbnail /><div class=\"dz-details\"><div class=\"dz-filename\"><span data-dz-name></span></div><div class=\"dz-size\" data-dz-size=\"Velikost:\"></div></div><div class=\"dz-progress\"><span class=\"dz-upload\" data-dz-uploadprogress></span></div><div class=\"dz-actions\"><a class=\"dz-remove\" data-dz-remove><i class=\"icon icon--close\"></i></a></div></div>",
                    init: function () {

                        this.on("addedfile", (file) => {
                            if(self._hasVideo){
                                //pokud uz je nahrane video, tak zrus upload
                                this.removeFile(file);
                            }
                            if(self._limitToImages && file.type.indexOf("video") > -1) {
                                //pokud uz je nahrany image a chces nahrat video, tak zrus upload
                                this.removeFile(file);
                            }
                        });

                        this.on("removedfile", (file) => {
                            let files = [];

                            self._hasVideo = false;
                            self._limitToImages = false;

                            this.files.forEach((item) => {
                                if(item.type === "video/mp4" || item.type === 'video/quicktime') {
                                    self._hasVideo = true;
                                }
                                if(item.type.indexOf("image") > -1) {
                                    self._limitToImages = true;
                                }
                                if (item.status === 'success') {
                                    files.push(item.uuid);
                                }
                            });

                            self.target.files.value = files.join();
                        });

                        this.on("success", (file, response) => {
                            file.uuid = response.uuid;
                            let files = [];

                            if(file.type === "video/mp4" || file.type === 'video/quicktime') {
                                self._hasVideo = true;
                            }
                            if(file.type.indexOf("image") > -1) {
                                self._limitToImages = true;
                            }

                            this.files.forEach(function (item) {
                                if (item.status === 'success') {
                                    files.push(item.uuid);
                                }
                            });

                            self.target.files.value = files.join();
                        });
                    }
                });
            })
        }
    }
});