application.register("comp_visual_main", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;

        const labels = element.querySelectorAll("[data-lib-ns-nav-item]");
        const nextBtn = element.querySelector("[data-lib-ns-next]");
        const slider = element.querySelector("[data-lib-ns]");

        self._activeBar = labels[0].querySelector("progress");
        self._autoplay = parseInt(element.dataset.autoplay) / 10;
        self._iter = 0;

        if (window.innerWidth > 960 && self._autoplay) {
            self.autoplayInterval = setInterval(() => {
                self._iter++;
                self._progress = (100 / self._autoplay) * self._iter;
                self._activeBar.value = self._progress;

                if (self._iter === self._autoplay) {
                    self._iter = 0;

                    if (slider.scrollLeft <= slider.children[0].clientWidth) {
                        slider.scroll({
                            left: slider.scrollLeft + slider.children[0].clientWidth,
                            behavior: "smooth"
                        });
                    } else {
                        slider.scroll({left: 0, behavior: "smooth"});
                    }
                }
            }, 1);
        }

        [...labels].forEach((label)=>{
            if (!document.documentElement.classList.contains("ie")) {
                let observer = new MutationObserver((mutationsList) => {
                    for (let mutation of mutationsList) {
                        if (mutation.target.classList.contains("state--active")) {
                            self._iter = 0;
                            self._activeBar = mutation.target.querySelector("progress");
                        }
                    }
                });
                observer.observe(label, {attributes: true, attributeFilter: ['class']});
            }
        });
    }

    disconnect() {
        clearInterval(this.autoplayInterval);
    }
});