application.register("part_form_search", class extends Stimulus.Controller {

    get target() {
        return {
            autocompleteArea: this.targets.find("autocompleteArea"),
        }
    }

    closeAutocomplete(e) {
        if (e.target.closest(this.element.className) === null && this.target.autocompleteArea.classList.contains("state--active")) {
            clearTimeout(this._searchTimeout);
            this.target.autocompleteArea.classList.add("state--hiding");
            this._searchTimeout = setTimeout(() => {
                this.target.autocompleteArea.classList.remove("state--active", "state--hiding");
            }, 300);
        }
    }

    doSearch(event) {
        clearTimeout(this._searchTimeout);

        let formData = new FormData();
        let query = event.currentTarget.value;
        let isSearching = 0;

        let searchIndicator = this.target.autocompleteArea.querySelector('input[name="searchSearching"]');
        if (searchIndicator !== null && searchIndicator.value === '1' && this.target.autocompleteArea.classList.contains("state--active")) {
            isSearching = 1;
        }

        formData.append('q', query);

        if (this.target.autocompleteArea !== null) {
            if (query.length > 2) {
                fetch(event.currentTarget.dataset.actionUrl, {
                    headers: {'X-Requested-With': 'XMLHttpRequest'},
                    method: 'POST',
                    body: formData
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    this.target.autocompleteArea.classList.add("state--active");
                    this.target.autocompleteArea.innerHTML = data.html;
                });
            } else {
                this.target.autocompleteArea.classList.remove("state--active");
            }
        }
    }

    doSearchInitial(e) {
        let query = e.currentTarget.value;

        if (query.length > 2) {
            if (typeof this.target.autocompleteArea !== "undefined") {
                this.target.autocompleteArea.classList.add("state--active");
            }
        }
    }

    handleKeypress(e) {
        if(e.keyCode === 13) {
            e.preventDefault();
            if (this.target.autocompleteArea !== null) {
                let link = this.target.autocompleteArea.querySelector("a");
                if(link !== null) {
                    link.dispatchEvent(new MouseEvent("click", {bubbles: true, cancelable: true, view: window}));
                }
            }
        }
    }
});