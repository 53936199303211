application.register("lib-expand", class extends Stimulus.Controller {
    connect() {
        this._isInitialized = false;
    }

    toggleExpand() {
        let self = this, element = self.element, body = self.targets.find("body");
        if (body.offsetHeight === 0) {
            body.style.height = body.scrollHeight + "px";
            element.classList.add("state--active");
        } else {
            if (!self._isInitialized) {
                body.style.height = body.offsetHeight + "px";
                body.offsetHeight;
                self._isInitialized = true;
            }
            body.style.height = "0px";
            element.classList.remove("state--active");
        }

        let parentExpand = element.closest('[data-target="lib-expand.body"]');

        if (parentExpand !== null) {
            parentExpand.style.height = null;
            parentExpand.offsetHeight;
            setTimeout(() => {
                parentExpand.style.height = parentExpand.scrollHeight + "px";
                if (typeof locomotive !== "undefined") {
                    setTimeout(() => {
                        locomotive.update();
                    }, 350);
                }
            }, 350);
        } else {
            if (typeof locomotive !== "undefined") {
                setTimeout(() => {
                    locomotive.update();
                }, 350);
            }
        }
    }
});