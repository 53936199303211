const cdnjs = {
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "seamless": "https://cdn.jsdelivr.net/npm/seamless-scroll-polyfill@1.0.9/dist/es5/seamless.min.js",
    "popper": "https://cdn.jsdelivr.net/npm/@popperjs/core@2.4.4/dist/umd/popper.min.js",
    "tippy": "https://cdn.jsdelivr.net/npm/tippy.js@6.2.6/dist/tippy.umd.js",
    "tippy_css": "https://cdn.jsdelivr.net/npm/tippy.js@6.2.6/animations/shift-away.css",
    "litepicker": "https://cdn.jsdelivr.net/npm/litepicker@1.5.7/dist/js/main.nocss.js",
    "litepicker_css": "https://cdn.jsdelivr.net/npm/litepicker@1.5.7/dist/css/style.css",
    "dayjs": "https://cdn.jsdelivr.net/npm/dayjs@1.8.35/dayjs.min.js",
    "dayjs_locale": "https://cdn.jsdelivr.net/npm/dayjs@1.8.35/locale/cs.js",
    "noui": "https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/10.1.0/nouislider.min.js",
    "slimselect": "https://cdn.jsdelivr.net/npm/slim-select@1.26.0/dist/slimselect.min.js",
    "slimselect_css": "https://cdn.jsdelivr.net/npm/slim-select@1.26.0/dist/slimselect.min.css",
    "jquery": "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "shareApiPolyfill": "https://cdn.jsdelivr.net/npm/share-api-polyfill@1.0.18/dist/share-min.js",
    "googlemaps": "https://maps.googleapis.com/maps/api/js?key={apikey}",
    "googlemaps_cluster": "https://cdn.jsdelivr.net/npm/@google/markerclustererplus@5.1.0/dist/markerclustererplus.min.js",
    "dropzone": "https://cdn.jsdelivr.net/npm/dropzone@5.9.2/dist/dropzone.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "swipedevents": "https://cdn.jsdelivr.net/npm/swiped-events@1.1.4/dist/swiped-events.min.js",
    "intlTelInput": "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.13/build/js/intlTelInput.min.js",
    "intlTelInput_css": "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.13/build/css/intlTelInput.min.css"
};
