window.lui_map_responses = {};

application.register("part_map", class extends Stimulus.Controller {

    get target() {
        return {
            map: this.targets.find("map"),
            infoWindow: this.targets.find("infoWindow"),
            info: this.targets.find("info"),
            infoTemplate: this.targets.find("infoTemplate"),
        }
    }

    connect() {
        let self = this, element = self.element, options = JSON.parse(element.dataset.options),
            markersUrl = element.dataset.markers;

        inView(element, () => {
            importScript([cdnjs.googlemaps.replace("{apikey}", options["apikey"]), cdnjs.googlemaps_cluster], () => {
                // let coords = options["center"].split(/,\s/);
                let bounds;
                let markers_temp = [];
                let info_template = self.target.infoWindow.innerHTML;
                let info_template_devices = self.target.infoTemplate.innerHTML;

                function ControlCustomZoom(controlDiv, map) {
                    let zoomIn = document.createElement('div');
                    zoomIn.classList.add("elm_part_control");
                    zoomIn.insertAdjacentHTML("beforeend", '<span class="icon icon--plus">');
                    zoomIn.addEventListener('click', function () {
                        map.setZoom(map.getZoom() + 1);
                    });

                    let zoomOut = document.createElement('div');
                    zoomOut.classList.add("elm_part_control");
                    zoomOut.insertAdjacentHTML("beforeend", '<span class="icon icon--minus">');
                    zoomOut.addEventListener('click', function () {
                        map.setZoom(map.getZoom() - 1);
                    });

                    controlDiv.appendChild(zoomIn);
                    controlDiv.appendChild(zoomOut);

                }

                let map = new google.maps.Map(self.target.map, {
                    // center: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])},
                    zoom: 12,
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]
                });

                let zoomControlDiv = document.createElement('div');
                let zoomControl = new ControlCustomZoom(zoomControlDiv, map);

                zoomControlDiv.index = 1;
                zoomControlDiv.classList.add("elm_part_zoom_wrapper");
                map.controls[google.maps.ControlPosition.RIGHT_TOP].push(zoomControlDiv);


                let infoWindow = new google.maps.InfoWindow({
                    content: "",
                });
                let icon = {
                    url: '/assets/images/marker.svg',
                    scaledSize: new google.maps.Size(24, 24),
                    anchor: new google.maps.Point(12, 24)
                };
                let iconActive = {
                    url: '/assets/images/marker-active.svg',
                    scaledSize: new google.maps.Size(24, 24),
                    anchor: new google.maps.Point(12, 24)
                };

                function getMarkers(url, callback) {
                    callback(JSON.parse(url));
                }

                function generateMarker(item) {
                    let title = item["title"];
                    let point = new google.maps.LatLng(parseFloat(item["latitude"]), parseFloat(item["longitude"]));
                    let content = info_template
                        .replaceAll("*title*", title)
                        .replaceAll("*image*", item["image"])
                        .replaceAll("*location*", item["location"])
                        .replaceAll("*price*", item["price"])
                        .replaceAll("*url*", item["url"]);

                    let marker = new google.maps.Marker({
                        title: title,
                        map: map,
                        position: point,
                        icon: icon,
                    });
                    bounds.extend(point);
                    markers_temp.push(marker);

                    function showInfo(marker) {
                        for (let marker_temp of markers_temp) {
                            marker_temp.setIcon(icon);
                        }
                        marker.setIcon(iconActive);

                        infoWindow.close();
                        self.target.info.classList.remove("state--active");

                        if (window.innerWidth > 960) {
                            infoWindow.setContent(content);
                            infoWindow.open(map, marker);
                        } else {
                            map.panTo({lat: marker.position.lat(), lng: marker.position.lng()});

                            self.target.info.innerHTML = info_template_devices
                                .replaceAll("*title*", title)
                                .replaceAll("*image*", item["image"])
                                .replaceAll("*location*", item["location"])
                                .replaceAll("*price*", item["price"])
                                .replaceAll("*url*", item["url"]);

                            self.target.info.classList.add("state--active");
                        }
                    }

                    marker.addListener('click', () => {
                        showInfo(marker);
                    });

                    marker.addListener('mouseover', () => {
                        showInfo(marker);
                    });
                }

                map.addListener("click", () => {
                    self.target.info.classList.remove("state--active");
                    infoWindow.close();
                    for (let marker_temp of markers_temp) {
                        marker_temp.setIcon(icon);
                    }
                })

                getMarkers(markersUrl, (payload) => {
                    bounds = new google.maps.LatLngBounds();
                    for (let item of payload) {
                        generateMarker(item);
                    }
                    let markerCluster = new MarkerClusterer(map, markers_temp, {
                        styles: [{
                            url: "/assets/images/marker-cluster.svg",
                            width: 36,
                            height: 36,
                            fontFamily: "Poppins,sans-serif",
                            fontWeight: "600",
                            textSize: 16,
                            textColor: "#FFFFFF",
                            textLineHeight: 36
                        }]
                    });

                    if (payload.length > 1) {
                        map.fitBounds(bounds);
                    } else {
                        map.setCenter({lat: payload[0].latitude, lng:payload[0].longitude});
                    }
                });
            });
        })
    }
});