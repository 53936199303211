application.register("lib-reveal", class extends Stimulus.Controller {
    connect() {
        let revealElms = this.element.querySelectorAll("[data-scroll]");

        revealElms.forEach((elem)=>{
            inView(elem,()=>{
                elem.classList.add("is-inview");
            })
        })
    }
});