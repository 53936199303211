application.register("part_cart_data", class extends Stimulus.Controller {

    connect() {
        this.toggleCompanyAction(document.getElementById('toggleCompany').classList.contains('active'));
    }

    toggleCompany(e) {
        this.toggleCompanyAction(e.currentTarget.checked);
    }

    toggleCompanyAction(checked) {

        if (checked) {
            this.targets.findAll('hidden').forEach(elm => {
                elm.removeAttribute('hidden')
            })
        }
        else {
            this.targets.findAll('hidden').forEach(elm => {
                elm.setAttribute('hidden',true)
            })
        }
    }

});
