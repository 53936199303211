application.register("part_item_account_review", class extends Stimulus.Controller {

    get target() {
        return {
            text: this.targets.find("text")
        }
    }

    connect() {
        this._expanded = false;
    }

    expand() {
        if(!this._expanded) {
            this._expanded = true;
            this.target.text.classList.add("is--animate");
            this.target.text.style.height = this.target.text.scrollHeight + "px";
            setTimeout(() => {
                this.target.text.classList.remove("is--animate");
                this.target.text.classList.add("state--active");
            },300);
        }
    }
});