application.register("part_ui_select", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element,
            select = element.querySelector("select");

        select.addEventListener("change", () => {
            element.classList.remove("state--placeholder");
            if (select.value === "") {
                element.classList.add("state--placeholder");
            }
        });

        bodyLoaded(() => {
            if (document.documentElement.classList.contains("android") || document.documentElement.classList.contains("ipad") || document.documentElement.classList.contains("iphone") || document.documentElement.classList.contains("ie")) {
                return;
            }

            importStyle(cdnjs.slimselect_css);
            importScript(cdnjs.slimselect, () => {
                element.classList.add("lib--slimselect");
                new SlimSelect({
                    select: select,
                    showSearch: false,
                    allowDeselect: !!(select.querySelector("[data-placeholder]")),
                    deselectLabel: '<span class="icon--close"></span>'
                })
            });
        });
    }
});