application.register("part_account_form_info", class extends Stimulus.Controller {

    get target() {
        return {
            delivery: this.targets.find("delivery")
        }
    }

    toggleDelivery(e) {
        if (!e.currentTarget.checked) {
            this.target.delivery.style.height = this.target.delivery.scrollHeight + "px";
        } else {
            if (!this._deliveryInitialized) {
                this.target.delivery.style.height = this.target.delivery.offsetHeight + "px";
                this.target.delivery.offsetHeight;
                this._deliveryInitialized = true;
            }
            this.target.delivery.style.height = "0px";
        }

        if (typeof locomotive !== "undefined") {
            setTimeout(() => {
                locomotive.update();
            }, 350);
        }
    }
});