application.register("comp_vouchers_variants", class extends Stimulus.Controller {

    get target() {
        return {
            images: this.targets.find("images"),
            options: this.targets.find("options")
        }
    }

    changeImage(e) {
        let index = parseInt(e.currentTarget.dataset.actionId);

        let activeOption = this.target.options.querySelector(".state--active");

        if (activeOption !== null) {
            activeOption.classList.remove("state--active");
        }
        e.currentTarget.classList.add("state--active");

        let activeImage = this.target.images.querySelector(".state--active");

        if (activeImage !== null) {
            activeImage.classList.remove("state--active");
        }

        this.target.images.querySelectorAll(".elm_images_item")[index].classList.add("state--active");
    }
});