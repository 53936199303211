application.register("comp_faq", class extends Stimulus.Controller {
    toggleExpand(e) {
        let self = this, element = self.element,
            currentItem = e.currentTarget.closest('[data-comp-ref="item"]'),
            currentBody = currentItem.querySelector('[data-item-ref="body"]');

        if (currentBody.offsetHeight === 0) {
            self.open(currentItem);
        } else {
            self.close(currentItem);
        }

        [...element.querySelectorAll('[data-comp-ref="item"]')].forEach((item) => {
            if (item !== currentItem) {
                self.close(item);
            }
        });

        if (typeof locomotive !== "undefined") {
            setTimeout(() => {
                locomotive.update();
            }, 350);
        }
    }

    open(item) {
        let body = item.querySelector('[data-item-ref="body"]');
        body.style.height = body.scrollHeight + "px";
        item.classList.add("state--active");
    }

    close(item) {
        let body = item.querySelector('[data-item-ref="body"]');
        if (!item._isInitialized) {
            body.style.height = body.offsetHeight + "px";
            body.offsetHeight;
            item._isInitialized = true;
        }
        body.style.height = "0px";
        item.classList.remove("state--active");
    }
});