application.register("comp_destination_head", class extends Stimulus.Controller {

    get target() {
        return {
            nav: this.targets.find("nav"),
            body: this.targets.find("body"),
            slider: this.targets.find("slider")
        }
    }

    connect() {
        let self = this, element = self.element;

        self._ticking = false;
        self._offsets = {};

        cssLoaded(() => {
            let layout_offset = element.offsetTop;

            if (typeof locomotive !== "undefined" && !locomotive.isMobile) {
                element.setAttribute("data-scroll-offset", element.querySelector(".wrp_comp_head").offsetHeight - document.querySelector("#layout_header").querySelector(".wrp_header").offsetHeight);
                locomotive.update();

                locomotive.on("scroll", (e) => {
                    self.onScroll(e.delta.y);
                });
            } else {
                element.nextElementSibling.style.marginTop = this.target.body.offsetHeight + this.target.slider.offsetHeight + "px";

                if (window.location.hash && document.querySelector(`#${window.location.hash.replace('#', '')}`) !== null) {
                    LibAnchor.animation(document.querySelector(`#${window.location.hash.replace('#', '')}`));
                }

                window.addEventListener('scroll', () => {
                    self.onScroll(window.scrollY);
                }, false);
            }

            [...document.querySelectorAll("[data-offset]")].forEach((section) => {
                let offset;
                if (window.innerWidth > 960) {
                    offset = (typeof section.dataset.offset !== "undefined") ? parseInt(section.dataset.offset) : 0;
                } else {
                    offset = 100;
                }
                self._offsets[section.getAttribute("id")] = section.offsetTop + layout_offset - offset * 2;
            });
        });
    }

    disconnect() {
        let self = this;
        window.removeEventListener('scroll', () => {
            self.onScroll(window.scrollY);
        }, false);
    }

    onScroll(currentScrollY) {
        let self = this, element = self.element;

        if (!self._ticking) {
            requestAnimationFrame(() => {
                for (let i in self._offsets) {
                    if (self._offsets[i] <= currentScrollY) {
                        let active = self.target.nav.querySelector(".state--active")
                        let newActive = self.target.nav.querySelector(`[href="#${i}"]`);
                        if (active !== null) {
                            active.classList.remove("state--active");
                        }
                        if (newActive !== null) {
                            newActive.classList.add("state--active");
                            self.target.nav.closest(".col--nav").scrollLeft = newActive.closest(".col").offsetLeft - 16;
                        }

                    }
                }

                element.classList.toggle("is--fixed", currentScrollY > this.target.slider.offsetHeight);
            });

            self._ticking = false;
        } else {
            self._ticking = true;
        }
    }

    openGallery(e) {
        let index = e.currentTarget.dataset.actionIndex;
        let gallery = document.querySelector(".comp_destination_gallery");
        if (gallery !== null && gallery.getAttribute("data-lib-gallery-loaded") === "true") {
            [...gallery.querySelectorAll("[data-lib-gallery-item]")][index].dispatchEvent(new Event('click', {bubbles: true, cancelable: true }));
        }
    }
});
