application.register("lib-datepicker", class extends Stimulus.Controller {
    connect() {
        inView(this.element,()=>{
            let lang = document.documentElement.getAttribute("lang");
            importStyle(cdnjs.litepicker_css);
            importScript([cdnjs.litepicker, cdnjs.dayjs, cdnjs.dayjs_locale.replace("/cs.js", `/${lang}.js`)], () => {
                let input = this.element.querySelector("input");

                dayjs.locale(lang);
                let dayjsLocale = eval(`window.dayjs_locale_${lang}`);
                let lockDays = input.dataset.lockDays;
                let maxDate = input.dataset.maxDate;
                let minDate = input.dataset.minDate;

                new Litepicker({
                    element: this.element.querySelector("input"),
                    lang: lang,
                    format: dayjsLocale.formats.L,
                    numberOfMonths: 1,
                    numberOfColumns: 1,
                    minDate: minDate ? minDate : dayjs(),
                    maxDate: maxDate ? maxDate : null,
                    inlineMode: false,
                    singleMode: true,
                    showTooltip: false,
                    lockDays: lockDays ? JSON.parse(lockDays) : [],
                    buttonText: {
                        previousMonth: '<span class="icon icon--arrow-left"></span>',
                        nextMonth: '<span class="icon icon--arrow-right"></span>'
                    },
                    onRender: (element) => {
                        element.querySelectorAll("[href]").forEach((elm) => {
                            elm.removeAttribute("href");
                            elm.setAttribute("data-no-swup", true);
                        })
                    }
                })
            });
        })
    }
});