application.register("lib-gallery", class extends Stimulus.Controller {
    connect() {
        let element = this.element;

        if(document.querySelector(".comp_destination_head") !== null) {
            this.createGallery();
        }
        else {
            inView(element, () => {
                this.createGallery();
            });
        }
    }

    createGallery() {
        let self = this, element = self.element;

        importStyle(cdnjs.fancybox_css);
        importScript([cdnjs.jquery, cdnjs.fancybox], () => {
            cssLoaded(() => {
                element.setAttribute("data-lib-gallery-loaded",true);
                $(element).fancybox({
                    selector: `[data-lib-gallery-id="${self.data.get("id")}"] [data-lib-gallery-item]`,
                    transitionEffect: "slide",
                    animationEffect: "zoom",
                    hideScrollbar: false,
                    loop: true,
                    autoFocus: false,
                    backFocus: false,
                    buttons: [
                        "close"
                    ]
                });
            });
        });
    }

    openGallery() {
        let element = this.element;
        element.querySelector("[data-lib-gallery-item]").dispatchEvent(new Event('click', {bubbles: true, cancelable: true }));
    }
});