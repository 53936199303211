application.register("part_item_story", class extends Stimulus.Controller {
    get target() {
        return {
            video: this.targets.find("video"),
            progressbar: this.targets.find("progressbar")
        }
    }

    connect() {
        this._loaded = false;
        this.target.video.addEventListener("load", () => {
            this._loaded = true;
            this.target.video.play();
        });
        this.target.video.addEventListener("canplay", () => {
            this.element.classList.add("is--loaded");
        });
    }

    playVideo() {
        if (window.innerWidth > 960) {
            if (!this._loaded) {
                this.target.video.dispatchEvent(new Event('load', {'bubbles': true}));
            } else {
                this.target.video.play();
            }
        }
    }

    pauseVideo() {
        if (window.innerWidth > 960) {
            this.target.video.pause();
        }
    }

    updateProgress() {
        let progress = Math.floor((100 / this.target.video.duration) * this.target.video.currentTime);
        if (!isNaN(progress)) {
            this.target.progressbar.value = progress;
        }
    }
});