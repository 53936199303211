application.register("part_dialog_story_add", class extends Stimulus.Controller {

    get target() {
        return {
            location: this.targets.find("location"),
            locationId: this.targets.find("locationId")
        }
    }

    setLocation(e) {
        this.target.location.value = e.currentTarget.dataset.actionValue;
        this.target.locationId.value = e.currentTarget.dataset.actionId;
    }
});