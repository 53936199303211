application.register("part_ui_file", class extends Stimulus.Controller {
    get target() {
        return {
            image: this.targets.find("image"),
            content: this.targets.find("content"),
            input: this.targets.find("input")
        }
    }

    connect() {

    }

    handleChange(e) {
        if(this.element.classList.contains("type--image")) {
            if(e.currentTarget.files[0].type.indexOf("image") > -1) {
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    if(this.element.classList.contains("state--active")) {
                        this.target.image.src = event.target.result;
                    }
                    else {
                        this.target.image.setAttribute("data-src", event.target.result);
                        this.element.classList.add("state--active");
                    }
                });
                reader.readAsDataURL(e.currentTarget.files[0]);
            }
        }
    }

    handleDrag(e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    }

    handleDrop(e) {
        e.stopPropagation();
        e.preventDefault();
        this.target.input.files = e.dataTransfer.files;
        this.target.input.dispatchEvent(new Event('change', {'bubbles': true, 'cancelable': true}));
    }
});